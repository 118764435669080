import './App.css';
import './Donate.css';
import {StompSessionProvider, useSubscription, useStompClient} from "react-stomp-hooks";
import React, {Component, useState} from "react";
import {Animated} from "react-animated-css";
import {DateTime} from "luxon";


class App extends Component {
    render() {
        const params = new URLSearchParams(window.location.search)
        const username = params.get("username")
        console.log("Connecting to " + process.env.REACT_APP_BACKEND_URL + "/donate");

        return username ? (
            <div>
                <button id={'hid'} style={{display: 'none'}}></button>
                <StompSessionProvider
                    url={process.env.REACT_APP_BACKEND_URL + "/donate"}
                    connectHeaders={
                        {'username': username}
                    }
                >
                    <SubscribingComponent />
                </StompSessionProvider>
            </div>
        ) : (
            <div>
                Provide userName with path.
            </div>
        )
    }
}

function SubscribingComponent() {
    const stompClient = useStompClient()

    const [isVisible, setVisible] = useState(false)
    const [incomingMessage, setIncomingMessage] = useState({message: "No message received yet"})
    let paymentId;

    const onEndSound = () => {
        console.log('Speech synthesis completed.');
        const params = new URLSearchParams(window.location.search)
        const username = params.get("username")

        let body = {}
        body.paymentId = paymentId
        body.userId = username
        body.shownAt = DateTime.utc()

        setVisible(false);

        console.log(body)

        stompClient.publish({
            destination: "/app/confirmation",
            body: JSON.stringify(body)
        })
    };

    useSubscription("/users/queue/messages", async (message) => {
        const incomingMessage = JSON.parse(message.body)
        console.log(incomingMessage)
        paymentId = incomingMessage.paymentId
        stompClient.publish({
            destination: "/app/ack",
            body: JSON.stringify(paymentId)
        })


        if (incomingMessage.settings.type === 'TEXT') {
            let audioContext = new AudioContext();
            fetch(process.env.REACT_APP_BACKEND_URL+`/payments/${paymentId}/text-to-speech`)
                .then(respone => respone.arrayBuffer())
                .then(buffer => {
                    audioContext.decodeAudioData(buffer, decodedData => {
                        const source = audioContext.createBufferSource();
                        source.buffer = decodedData;

                        source.connect(audioContext.destination);
                        source.start(0);
                        source.onended = () => onEndSound()

                    })
                })
                .catch(error => {
                    console.log(error)
                })

            setVisible(true);
            setIncomingMessage(incomingMessage)
        }
    });

    if (!incomingMessage.settings) {
        return <div></div>;
    }

    return (
            <Animated animationIn="lightSpeedIn" animationOut="zoomOutDown" animationInDuration={1000} animationOutDuration={1000}
                      isVisible={isVisible}>
                <div>
                    <span style={{
                        fontSize: incomingMessage.settings.headerFontSize + 'px',
                        margin: '4px',
                        color: incomingMessage.settings.headerFontColor,
                        fontFamily: `${incomingMessage.settings.headerFontStyle}`,
                        WebkitTextStrokeColor: incomingMessage.settings.headerFontOutlineColor,
                        WebkitTextStrokeWidth: incomingMessage.settings.headerFontOutlineSize + 'px'
                    }}>{incomingMessage.name} wpłacił {incomingMessage.amount} zł</span>
                </div>
                <div>
                    <span style={{
                        fontSize: incomingMessage.settings.messageFontSize + 'px',
                        margin: '4px',
                        color: incomingMessage.settings.messageFontColor,
                        fontFamily: `${incomingMessage.settings.messageFontStyle}`,
                        WebkitTextStrokeColor: incomingMessage.settings.messageFontOutlineColor,
                        WebkitTextStrokeWidth: incomingMessage.settings.messageFontOutlineSize + 'px'
                    }}>{incomingMessage.message}</span>
                </div>
            </Animated>
    );
}

export default App;
